"use client";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import formatSelection from "@/lib/centra/formatters/formatSelection";
import useLocation from "@/lib/hooks/useLocation";
import usePersistedStore from "@/lib/stateManagement/persistedState/persistedStore";

import { useGiftToken } from "@frend-digital/future-centra/client";
import type { UseSelectionReturn } from "../useSelection/useSelection";
export const optimisticUpdateLocation = (
  cart: UseSelectionReturn,
  variables: {
    locationId: string;
  },
) => {
  const currentCountry = cart.countries.find(
    c => c?.country === variables.locationId,
  );
  const newLocation = {
    country: currentCountry?.country || "",
    name: currentCountry?.name || "",
    eu: currentCountry?.eu || false,
  };
  return {
    ...cart,
    location: { ...cart.location, ...newLocation },
    selection: {
      ...cart.selection,
      currency: cart.countries.find(c => c?.country === variables.locationId)
        ?.currency,
    },
  };
};
export const setLocation = async (
  variables: {
    locationId: string;
    stateId?: string;
  },
  customerToken: string,
  current: any,
) => {
  if (window.CentraCheckout) window.CentraCheckout.suspend();
  const { locationId, stateId } = variables;
  const api = process.env.NEXT_PUBLIC_CENTRA_CHECKOUT_API;
  const url = stateId
    ? `${api}/countries/${locationId}/states/${stateId}`
    : `${api}/countries/${locationId}`;
  const res = await fetch(url, {
    method: "PUT",
    headers: { "API-token": customerToken || "" },
  });
  if (!res.ok) throw Error("Error changing location");
  const data = await res.json();
  return data;
};

export const setCampaign = async (
  variables: {
    uri: string;
  },
  customerToken: string,
) => {
  if (window.CentraCheckout) window.CentraCheckout.suspend();
  const { uri } = variables;
  const api = process.env.NEXT_PUBLIC_CENTRA_CHECKOUT_API;
  const url = `${api}/campaign-site`;
  const res = await fetch(url, {
    method: "PUT",
    headers: { "API-token": customerToken || "" },
    body: JSON.stringify({ uri: uri }),
  });
  if (window.CentraCheckout) window.CentraCheckout.resume();

  if (!res.ok) throw Error("Error changing location");
  const data = await res.json();
  return data;
};

export const useSetCampaign = () => {
  const queryClient = useQueryClient();
  const customerToken = usePersistedStore(state => state.token);

  return useMutation({
    mutationKey: ["campaign"],
    mutationFn: async (variables: { uri: string }) => {
      return await setCampaign(variables, customerToken);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["selection"] });
    },
  });
};

export const useChangeGiftCardLocation = () => {
  const queryClient = useQueryClient();

  const customerToken = useGiftToken();
  const giftCardKey = ["gift", customerToken];
  const { states, state, currentCountry, countries } = useLocation();
  const changeLocation = useMutation({
    mutationFn: async ({
      locationId,
      stateId,
    }: {
      locationId: string;
      stateId?: string;
    }) => {
      const targetLocation = countries.find(c => c?.country === locationId);
      if (!targetLocation?.country) return;

      const targetState = (() => {
        if (!targetLocation?.states) return undefined;
        if (stateId)
          return targetLocation?.states.find(s => s?.state === stateId);
        return targetLocation?.states?.[0]; // default to first state
      })();

      await queryClient.cancelQueries({
        queryKey: giftCardKey,
      });

      return await setLocation(
        {
          locationId: targetLocation?.country,
          stateId: targetState?.state,
        },
        customerToken!,
        {
          states,
          state,
          currentCountry,
        },
      );
    },

    onMutate: async (variables: { locationId: string }) => {
      // Snapshot the previous value
      const oldData = queryClient.getQueryData(giftCardKey);

      // Cancel any outgoing refetches (so they don't overwrite our optimistic update)

      // Optimistically update to the new value
      await queryClient.setQueryData(giftCardKey, (oldCart: any) => {
        return optimisticUpdateLocation(oldCart, variables);
      });
      return { oldData };
    },

    onSuccess: async (data: any) => {
      return await queryClient.setQueryData(giftCardKey, formatSelection(data));
    },

    onSettled: async (
      data: any,
      error: any,
      variables: { locationId: string },
      context: any,
    ) => {
      return await queryClient.invalidateQueries({ queryKey: giftCardKey });
    },
  });

  return { changeLocation };
};

const useChangeLocation = () => {
  const queryClient = useQueryClient();
  const customerToken = usePersistedStore(state => state.token);
  const setToken = usePersistedStore(state => state.setToken);
  const { states, state, currentCountry, countries } = useLocation();
  const changeLocation = useMutation({
    mutationFn: async ({
      locationId,
      stateId,
    }: {
      locationId: string;
      stateId?: string;
    }) => {
      const targetLocation = countries.find(c => c?.country === locationId);
      if (!targetLocation?.country) return;

      const targetState = (() => {
        if (!targetLocation?.states) return undefined;
        if (stateId)
          return targetLocation?.states.find(s => s?.state === stateId);
        return targetLocation?.states?.[0]; // default to first state
      })();

      await queryClient.cancelQueries({
        queryKey: ["selection"],
      });

      return await setLocation(
        {
          locationId: targetLocation?.country,
          stateId: targetState?.state,
        },
        customerToken,
        {
          states,
          state,
          currentCountry,
        },
      );
    },

    onMutate: async (variables: { locationId: string }) => {
      // Snapshot the previous value
      const oldData = queryClient.getQueryData(["selection"]);

      // Cancel any outgoing refetches (so they don't overwrite our optimistic update)

      // Optimistically update to the new value
      await queryClient.setQueryData(["selection"], (oldCart: any) => {
        return optimisticUpdateLocation(oldCart, variables);
      });
      return { oldData };
    },

    onSuccess: async (data: any) => {
      setToken(data?.token);
      return await queryClient.setQueryData(
        ["selection"],
        formatSelection(data),
      );
    },

    onSettled: async (
      data: any,
      error: any,
      variables: { locationId: string },
      context: any,
    ) => {
      setToken(data?.token || context?.oldData?.token);
      return await queryClient.invalidateQueries({ queryKey: ["selection"] });
    },
  });

  return { changeLocation };
};

export default useChangeLocation;
